










































import Service from '../services/issues';
import EndPoint from '../services/endpoint';
import Vue from 'vue';
import KpiCard from '@/components/KpiCard.vue'; // @ is an alias to /src
import { ObjectiveKind } from '../types/gitlab';
import Filtered from '@/components/Filter.vue';

export default Vue.extend({
  name: 'Travaux',
  components: {
    KpiCard,
    Filtered,
  },
  data() {
    return {
      customerId: 'admin',
      selectedKpi: '',
      item1: false,
      item2: false,
      item3: false,
      item4: false,
      timer: 0,
    };
  },
  props: {
    customer: String,
    date: String,
  },
  async mounted() {
    if (localStorage.customerId) {
      this.customerId = localStorage.customerId;
    } else {
      await this.$router.push('/Login');
    }
    if (this.$route.query?.q) {
      localStorage.selectedKpi = this.$route.query.q;
    }
    await this.getItem();
    this.interval();
    console.log('http://localhost:8080/token?access_token=' + localStorage.accessToken + '&expires_in=168880480000');
    this.$root.$on('currentClient', data => {
      if (this.$route.path != '/travaux') return;

      this.refreshData(data);
    });
  },
  watch: {
    customer: function(newVal, oldVal) {
      this.customerId = this.customer;
      this.getItem();
    },
    date: function(newVal, oldVal) {
      this.getItem();
    },
    $route(to, from) {
      if (to.query?.q) {
        localStorage.selectedKpi = to.query.q;
      }
      this.getItem();
    },
  },
  destroyed(): void {
    clearInterval(this.timer);
  },
  methods: {
    async refreshData(a) {
      if (a.customerId) this.customerId = a.customerId;
      if (a.selectedKpi) this.selectedKpi = a.selectedKpi;
      this.getItem();
    },
    interval() {
      if (this.timer > 0) {
        clearInterval(this.timer);
        this.timer = 0;
      }
      this.timer = window.setInterval(() => this.getItem(), 60000); //refresh 1 minute
    },
    async getItem() {
      await Service.getKpi1(
        ObjectiveKind.UNASSIGNED_CHANGE,
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.UNASSIGNED_CHANGE),
      ).then(response => {
        this.item1 = response;
      });
      Service.getKpi2(
        ObjectiveKind.UNSCHEDULED_CHANGE,
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.UNSCHEDULED_CHANGE),
      ).then(response => {
        this.item2 = response;
      });
      Service.getKpi3(
        ObjectiveKind.PLANNED_CHANGE,
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.PLANNED_CHANGE),
      ).then(response => {
        this.item3 = response;
      });
      Service.getKpi4(
        'replannifie',
        EndPoint.get(this.customerId) + EndPoint.params(ObjectiveKind.PLANNED_CHANGE),
      ).then(response => {
        this.item4 = response;
      });
    },
  },
});
